import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateZeroDpExpiryTime } from "../finance-post-payment-loan-application/actions";
import ZeroDpTimerText from "./component";

const mapStateToProps = ({
    checkout: {
        financeData
    },
    carDetails: {
        content
    }
}) => ({
    financeData,
    content
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateZeroDpExpiryTimeConnect: updateZeroDpExpiryTime
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ZeroDpTimerText);
