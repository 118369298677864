import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import ZeroIcon from "./images/zero-icon.png";

const NotificationCard = ({
    img = ZeroIcon,
    heading = <p>Opted-in for <span>zero</span> deposit</p>,
    subHeader = "Car can be reserved at $0 now",
    onClickSubheader = () => { },
    glowHelp = false
}) => {
    return (
        <div styleName={"styles.outer"}>
            <div styleName={`styles.imgWrapper ${glowHelp ? "styles.glowHelp" : ""}`}>
                <img src={img} />
            </div>
            <div>
                <p styleName={"styles.heading"}>{heading}</p>
                <p styleName={"styles.subHeading"} onClick={onClickSubheader}>{subHeader}</p>
            </div>
        </div>
    );
};

NotificationCard.propTypes = {
    img: PropTypes.object,
    glowHelp: PropTypes.bool,
    heading: PropTypes.string,
    subHeader: PropTypes.string,
    onClickSubheader: PropTypes.func
};

export default NotificationCard;
