import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateZeroDpExpiryTime } from "../finance-post-payment-loan-application/actions";
import ZeroDpFormOnTime from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateZeroDpExpiryTimeConnect: updateZeroDpExpiryTime
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ZeroDpFormOnTime);
