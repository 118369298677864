/* eslint-disable no-magic-numbers */
import React from "react";
import useCountDown from "../../../hooks/use-countdown";
import styles from "./styles.css";
import PropTypes from "prop-types";
import CircularProgressBar from "../circular-progress-bar";
import { NUMBER } from "../../../constants/app-constants";

export const CoundownTimerText = ({
    expiryTime,
    startTime,
    timeFontSize = 24,
    displayExtensionBeforeTimeoutInMins,
    color,
    expiryTimeView = true
}) => {
    // eslint-disable-next-line no-unused-vars
    const [days, hours, minutes, seconds, fullHours, timeCompletePercentage, lessMinsLeft] = useCountDown(expiryTime, startTime, displayExtensionBeforeTimeoutInMins);
    const colorStyle = !expiryTimeView ? "#2A3B60" : color || (lessMinsLeft ? "#EC5856" : "#2A3B60");
    return (
        <span styleName={"styles.timerText1"} style={{color: colorStyle, fontSize: `${timeFontSize}px`, lineHeight: `${timeFontSize}px`}}>
            {parseInt(fullHours) > 0 ? `${fullHours}:` : null}
            {parseInt(minutes) >= 0 ? `${minutes}:` : "00"}
            {parseInt(seconds) >= 0 ? `${seconds}` : ":00"}
        </span>

    );
};

CoundownTimerText.propTypes = {
    expiryTime: PropTypes.string,
    expiryTimeView: PropTypes.bool,
    timerSize: PropTypes.number,
    displayExtensionBeforeTimeoutInMins: PropTypes.number,
    startTime: PropTypes.string,
    timeFontSize: PropTypes.string,
    color: PropTypes.string,
    textFontSize: PropTypes.string
};

const ClockTimerCircle = ({
    expiryTime,
    startTime,
    timerSize = 158,
    timeFontSize = 24,
    textFontSize = 14,
    strokeWidth = NUMBER.FIVE,
    displayExtensionBeforeTimeoutInMins,
    expiryTimeView = true
}) => {
    // eslint-disable-next-line no-unused-vars
    const [days, hours, minutes, seconds, fullHours, timeCompletePercentage, lessMinsLeft] = useCountDown(expiryTime, startTime, displayExtensionBeforeTimeoutInMins);
    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.timer"}>
                <CircularProgressBar
                    percentage= {timeCompletePercentage}
                    sqSize = {timerSize}
                    strokeWidth = {strokeWidth}
                    stroke = {expiryTimeView && lessMinsLeft ? "#EC5856" : "#0082D0"}
                />
            </div>
            <div styleName={"styles.timerWrapper"}>
                <div style={{fontSize: `${timeFontSize}px`, lineHeight: `${timeFontSize}px`}}>
                    <CoundownTimerText
                        expiryTime={expiryTime}
                        startTime={startTime}
                        timerSize={timerSize}
                        timeFontSize={timeFontSize}
                        textFontSize={textFontSize}
                        expiryTimeView={expiryTimeView}
                    />
                </div>
                <div styleName={"styles.timeLeftText"} style={{fontSize: `${textFontSize}px`}}>
                Time left
                </div>
            </div>
        </div>
    );
};

ClockTimerCircle.propTypes = {
    expiryTime: PropTypes.string,
    timerSize: PropTypes.number,
    displayExtensionBeforeTimeoutInMins: PropTypes.number,
    startTime: PropTypes.string,
    strokeWidth: PropTypes.number,
    timeFontSize: PropTypes.string,
    expiryTimeView: PropTypes.bool,
    textFontSize: PropTypes.string
};

export default ClockTimerCircle;
