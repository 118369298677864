import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const InputTextarea = (
    {
        label = "Start typing...",
        placeholder = "Start typing...",
        showPlaceHolderAsLabel = true,
        onChange,
        maxHeight = "auto",
        rest
    }) => {

    const textAreaRef = useRef();

    React.useLayoutEffect(() => {
        if (maxHeight !== "auto") {
            textAreaRef.current.style.height = `${maxHeight}px`;
        }
    }, [maxHeight]);

    const onChangeHandler = (e) => {
        if (textAreaRef.current.scrollTop !== 0 && maxHeight === "auto") {
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
        onChange(e);
    };

    return (
        <div styleName={"styles.inputShift"}>
            <textarea
                id= "textarea"
                ref={textAreaRef}
                className="form-control"
                placeholder={placeholder}
                onChange={onChangeHandler}
                {...rest}
            />
            {showPlaceHolderAsLabel && <label>{label}</label>}
        </div>
    );
};

InputTextarea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    showPlaceHolderAsLabel: PropTypes.bool,
    onChange: PropTypes.func,
    maxHeight: PropTypes.number,
    rest: PropTypes.object
};

export default InputTextarea;
