/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import Types from "./types";
import { B2cFinanceService } from "../../../service";
import { showToast } from "../../shared/toast-message/actions";
import { NUMBER } from "../../../constants/app-constants";

const getFinancePostPaymentApplicationInit = () => ({
    type: Types.GET_FINANCE_POST_PAYMENT_APPLICATION_INIT
});

const getFinancePostPaymentApplicationSuccess = (data) => ({
    type: Types.GET_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS,
    data
});

const getFinancePostPaymentApplicationFailure = (error) => ({
    type: Types.GET_FINANCE_POST_PAYMENT_APPLICATION_FAILURE,
    error
});

const getFinancePostPaymentApplication = (orderIdParam = null) => (dispatch, getState) => {
    dispatch(getFinancePostPaymentApplicationInit());
    const {
        checkout: {
            order: {
                orderId
            }
        },
        user: {
            secureToken
        }
    } = getState();
    return new Promise((resolve, reject) => {
        B2cFinanceService.getFinancePostPaymentApplication(secureToken, orderIdParam || orderId)
            .then(response => {
                dispatch(getFinancePostPaymentApplicationSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getFinancePostPaymentApplicationFailure(error));
                reject(error);
            });
    });
};

const getStageTwoMetaDataRequest = () => ({
    type: Types.GET_STAGE_TWO_METADATA_REQUEST
});

const getStageTwoMetaDataSuccess = (data) => ({
    type: Types.GET_STAGE_TWO_METADATA_SUCCESS,
    data
});

const getStageTwoMetaDataFailure = (error) => ({
    type: Types.GET_STAGE_TWO_METADATA_FAILURE,
    error
});

const getStageTwoMetaData = ({scenario}) =>
    async (dispatch, getState) => {
        dispatch(getStageTwoMetaDataRequest());
        const {
            user: {
                secureToken,
                isGuestLogin
            }
        } = getState();
        try {
            const response = await B2cFinanceService.getPreApprovalQuestions({ token: isGuestLogin ? null : secureToken, scenario });
            dispatch(getStageTwoMetaDataSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getStageTwoMetaDataFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return  Promise.reject(error);
        }
    };

const patchFinancePostPaymentApplicationInit = () => ({
    type: Types.PATCH_FINANCE_POST_PAYMENT_APPLICATION_INIT
});

const patchFinancePostPaymentApplicationSuccess = (data) => ({
    type: Types.PATCH_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS,
    data
});

const patchFinancePostPaymentApplicationFailure = (error) => ({
    type: Types.PATCH_FINANCE_POST_PAYMENT_APPLICATION_FAILURE,
    error
});

const patchFinancePostPaymentApplication = (formId, payload) => (dispatch, getState) => {
    dispatch(patchFinancePostPaymentApplicationInit());
    const {
        checkout: {
            order: {
                orderId
            }
        },
        user: {
            secureToken
        }
    } = getState();
    const params = { orderId, token: secureToken, formId };
    const updatedPayload = { ...payload };

    if (updatedPayload.currentAddressDuration && updatedPayload.currentAddressDuration.year >= NUMBER.THREE && updatedPayload.previousAddressDuration) {
        updatedPayload.previousAddressDuration = null;
    }

    return new Promise((resolve, reject) => {
        B2cFinanceService.patchFinancePostPaymentApplication(params, updatedPayload)
            .then(response => {
                dispatch(patchFinancePostPaymentApplicationSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(patchFinancePostPaymentApplicationFailure(error));
                reject(error);
            });
    });
};

const patchFinanceUploadDocument = (formId, payload) => (dispatch, getState) => {
    dispatch(patchFinancePostPaymentApplicationInit());
    const {
        checkout: {
            order: {
                orderId
            }
        },
        user: {
            secureToken
        }
    } = getState();
    const params = { orderId, token: secureToken, formId};
    return new Promise((resolve, reject) => {
        B2cFinanceService.patchFinanceUploadDocument(params, payload)
            .then(response => {
                dispatch(patchFinancePostPaymentApplicationSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(patchFinancePostPaymentApplicationFailure(error));
                reject(error);
            });
    });
};

const postFinancePostPaymentApplicationInit = () => ({
    type: Types.POST_FINANCE_POST_PAYMENT_APPLICATION_INIT
});

const postFinancePostPaymentApplicationSuccess = (data) => ({
    type: Types.POST_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS,
    data
});

const postFinancePostPaymentApplicationFailure = (error) => ({
    type: Types.POST_FINANCE_POST_PAYMENT_APPLICATION_FAILURE,
    error
});

const postFinancePostPaymentApplication = (formId, payload) => (dispatch, getState) => {
    dispatch(postFinancePostPaymentApplicationInit());
    const {
        checkout: {
            order: {
                orderId
            }
        },
        user: {
            secureToken
        }
    } = getState();
    const params = { orderId, token: secureToken, formId};
    return new Promise((resolve, reject) => {
        B2cFinanceService.postFinancePostPaymentApplication(params, payload)
            .then(response => {
                dispatch(postFinancePostPaymentApplicationSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(postFinancePostPaymentApplicationFailure(error));
                reject(error);
            });
    });
};

const postFinanceUploadDocument = (formId, payload) => (dispatch, getState) => {
    dispatch(postFinancePostPaymentApplicationInit());
    const {
        checkout: {
            order: {
                orderId
            }
        },
        user: {
            secureToken
        }
    } = getState();
    const params = { orderId, token: secureToken, formId};
    return new Promise((resolve, reject) => {
        B2cFinanceService.postFinanceUploadDocument(params, payload)
            .then(response => {
                dispatch(postFinancePostPaymentApplicationSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(postFinancePostPaymentApplicationFailure(error));
                reject(error);
            });
    });
};

const financeUploadDocument = (payload) => (dispatch, getState) => {
    const {
        checkout: {
            order: {
                orderId
            }
        },
        user: {
            secureToken
        }
    } = getState();
    const params = { orderId, token: secureToken};
    return new Promise((resolve, reject) => {
        B2cFinanceService.financeUploadDocument(params, payload)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const getPreSignedFinanceUploadedImageInit = () => ({
    type: Types.GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_INIT
});

const getPreSignedFinanceUploadedImageSuccess = (data, filePath) => ({
    type: Types.GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_SUCCESS,
    data,
    filePath
});

const getPreSignedFinanceUploadedImageFailure = (error) => ({
    type: Types.GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_FAILURE,
    error
});

const getPreSignedFinanceUploadedImage = ({payload, fileName}) => (dispatch, getState) => {
    dispatch(getPreSignedFinanceUploadedImageInit());
    const {
        user: {
            secureToken
        }
    } = getState();
    const { filePath } = payload;
    return new Promise((resolve, reject) => {
        B2cFinanceService.getPreSignedFinanceUploadedImage(secureToken, payload)
            .then(response => {
                dispatch(getPreSignedFinanceUploadedImageSuccess({
                    imgSrc: response.data,
                    fileName,
                    filePath
                }, filePath));
                resolve(response.data);
            }).catch(error => {
                dispatch(getPreSignedFinanceUploadedImageFailure(error));
                reject(error);
            });
    });
};

const updateZeroDpExpiryTimeInit = () => ({
    type: Types.UPDATE_ZERO_EXPIRY_TIME_INIT
});

const updateZeroDpExpiryTimeSuccess = (data) => ({
    type: Types.UPDATE_ZERO_EXPIRY_TIME_SUCCESS,
    data
});

const updateZeroDpExpiryTimeFailure = (error) => ({
    type: Types.UPDATE_ZERO_EXPIRY_TIME_FAILURE,
    error
});

const updateZeroDpExpiryTime = (payload) => (dispatch, getState) => {
    dispatch(updateZeroDpExpiryTimeInit());
    const {
        user: {
            secureToken
        },
        checkout: {
            order
        }
    } = getState();
    const { orderId } = order || {};
    return new Promise((resolve, reject) => {
        B2cFinanceService.updateZeroDpExpiryTime(secureToken, orderId, payload)
            .then(response => {
                dispatch(updateZeroDpExpiryTimeSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(updateZeroDpExpiryTimeFailure(error));
                reject(error);
            });
    });
};

const autoUpdateZeroDpExpiryTimeInit = () => ({
    type: Types.AUTO_UPDATE_ZERO_EXPIRY_TIME_INIT
});

const autoUpdateZeroDpExpiryTimeSuccess = (data) => ({
    type: Types.AUTO_UPDATE_ZERO_EXPIRY_TIME_SUCCESS,
    data
});

const autoUpdateZeroDpExpiryTimeFailure = (error) => ({
    type: Types.AUTO_UPDATE_ZERO_EXPIRY_TIME_FAILURE,
    error
});

const autoUpdateZeroDpExpiryTime = (payload) => (dispatch, getState) => {
    dispatch(autoUpdateZeroDpExpiryTimeInit());
    const {
        user: {
            secureToken
        },
        checkout: {
            order
        }
    } = getState();
    const { orderId } = order || {};
    return new Promise((resolve, reject) => {
        B2cFinanceService.autoUpdateZeroDpExpiryTime(secureToken, orderId, payload)
            .then(response => {
                dispatch(autoUpdateZeroDpExpiryTimeSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(autoUpdateZeroDpExpiryTimeFailure(error));
                reject(error);
            });
    });
};
const getStApplicationStatusStart = () => ({
    type: Types.GET_ST_APPLICATION_STATUS_START
});

const getStApplicationStatusSuccess = (data) => ({
    type: Types.GET_ST_APPLICATION_STATUS_SUCCESS,
    data
});

const getStApplicationStatusFailure = (error) => ({
    type: Types.GET_ST_APPLICATION_STATUS_FAILURE,
    error
});

const getStApplicationStatus = (orderId) => (dispatch, getState) => {
    const {
        user: { secureToken }
    } = getState();
    dispatch(getStApplicationStatusStart());
    return new Promise((resolve, reject) => {
        B2cFinanceService.getStApplicationStatus({orderId, token: secureToken}).then((response) => {
            dispatch(getStApplicationStatusSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(getStApplicationStatusFailure(error));
            reject(error);
        });
    });
};

export {
    getFinancePostPaymentApplication,
    patchFinancePostPaymentApplication,
    postFinancePostPaymentApplication,
    patchFinanceUploadDocument,
    postFinanceUploadDocument,
    financeUploadDocument,
    getPreSignedFinanceUploadedImage,
    updateZeroDpExpiryTime,
    autoUpdateZeroDpExpiryTime,
    getStageTwoMetaData,
    getStApplicationStatus
};
