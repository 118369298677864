import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postReBookZeroDpOrder } from "../checkout-info/actions";
import { softResetCheckoutData } from "../checkout-info/actions";
import { getZeroDpConfig } from "../../au.configuration/actions";
import ZeroDpReBookCar from "./component";
import { fetchCarDetails } from "../car-details/actions";

const mapStateToProps = ({
    user: {
        secureToken
    }
}) => ({
    secureToken
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    postReBookZeroDpOrderConnect: postReBookZeroDpOrder,
    softResetCheckoutDataConnect: softResetCheckoutData,
    getZeroDpConfigConnect: getZeroDpConfig,
    fetchCarDetailsConnect: fetchCarDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ZeroDpReBookCar);
