import React, { useState } from "react";
import styles from "./styles.css";
import InputRadioButton from "../../shared/input-radio-button";
import InputTextarea from "../../shared/input-textarea";
import Button from "../../shared/button";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";

const ZeroDpFormOnTime = ({
    onSubmit,
    onBack,
    updateZeroDpExpiryTimeConnect
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState();
    const [notes, setNotes] = useState();
    const reasons = [
        {text: "Need more clarity on a few questions", showTextArea: false},
        {text: "Time was insufficient", showTextArea: false},
        {text: "Technical issues at my side", showTextArea: false},
        {text: "Don't have all documents handy", showTextArea: false},
        {text: "CARS24 team is not reachable", showTextArea: false},
        {text: "Other reasons", showTextArea: true}
    ];

    const onClickReason = (value) => {
        setReason(value);
    };

    const onChange = (e) => {
        const value = e.target.value;
        setNotes(value);
    };

    const handleNeedMoreTimeSubmit = async () => {
        try {
            setIsLoading(true);
            const payload = {
                reason: reason.text,
                remarks: notes
            };
            await updateZeroDpExpiryTimeConnect(payload);
            onSubmit();
        } catch (err) {
            setIsLoading(false);
            //err
        }
    };

    return (
        <Modal isOpen={true} close={onBack}>
            <div styleName={"styles.outer"}>
                <h2>Please specify why you couldn’t fill the form on time?</h2>
                {
                    reasons.map((value, key) =>
                        (<div styleName={"styles.radioSection"} key={key}>
                            <InputRadioButton onClick={() => onClickReason(value)} id={key} value={value.text} />
                            <p>{value.text}</p>
                        </div>)
                    )
                }
                {(reason || {}).showTextArea && <div styleName={"styles.textAera"}>
                    <InputTextarea onChange={onChange}/>
                </div>}
                <div styleName={"styles.buttonWrapper"}>
                    <div styleName={"styles.viewButton"}>
                        <Button text={"Close"} onClick={onBack} />
                    </div>
                    <div styleName={"styles.bookButton"}>
                        <Button text="SUBMIT" onClick={handleNeedMoreTimeSubmit} isLoading={isLoading} disabled={!reason} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ZeroDpFormOnTime.propTypes = {
    onSubmit: PropTypes.func,
    updateZeroDpExpiryTimeConnect: PropTypes.func,
    onBack: PropTypes.func
};

export default ZeroDpFormOnTime;
