import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { NUMBER } from "../../../constants/app-constants";

const CircularProgressBar = ({
    sqSize = NUMBER.HUNDRED,
    percentage =  NUMBER.ZERO,
    strokeWidth = NUMBER.FIVE,
    children,
    stroke = "red"
}) => {
    // Size of the enclosing square
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / NUMBER.TWO;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * NUMBER.TWO;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * percentage / NUMBER.HUNDRED;
    const halfSqSize = sqSize / NUMBER.TWO;
    return (
        <svg
            width={sqSize}
            height={sqSize}
            viewBox={viewBox}>
            <circle
                styleName="styles.circle-background"
                cx={halfSqSize}
                cy={halfSqSize}
                r={radius}
                strokeWidth={`${strokeWidth}px`} />
            <circle
                styleName="styles.circle-progress"
                cx={halfSqSize}
                cy={halfSqSize}
                r={radius}

                strokeWidth={`${strokeWidth}px`}
                // Start progress marker at 12 O'Clock
                transform={`rotate(-90 ${halfSqSize} ${halfSqSize})`}
                style={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset,
                    stroke
                }} />
            <text
                styleName="styles.circle-text"
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle">
                {children}
            </text>
        </svg>
    );
};

CircularProgressBar.propTypes = {
    sqSize: PropTypes.number,
    percentage: PropTypes.number,
    stroke: PropTypes.string,
    strokeWidth: PropTypes.number,
    children: PropTypes.object
};

export default CircularProgressBar;
